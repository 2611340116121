import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

//import Call from '@material-ui/icons/Call';
//import Email from '@material-ui/icons/Email';
import RoomIcon from '@material-ui/icons/Room';
import Para1 from './img/contact1.jpg';
import Leave from './leave'
import AOS from 'aos';
import "aos/dist/aos.css";
import Email from '@material-ui/icons/Email';
import Call from '@material-ui/icons/Call';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import Twitter from '@material-ui/icons/Twitter';
import GitHub from '@material-ui/icons/GitHub';
import Particles from 'react-tsparticles' 
import swal from 'sweetalert';
import LockIcon from '@material-ui/icons/Lock';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Cvmain from "./cv_joseph.pdf"

import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AlarmIcon from '@material-ui/icons/Alarm';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import TiltPhaseSix from './TiltPhaseSix';
import User from './img/logo/password.svg';
import { useEffect } from 'react';
const options = {
    max: 5,
    perspective: 700,
    scale: 1.05,
}

const useStyles = makeStyles((theme) => ({

    large: {
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(20),
            height: theme.spacing(20),
            marginLeft: '20%'

        }, [theme.breakpoints.up('lg')]: {
            width: theme.spacing(43),
            height: theme.spacing(43),
            marginLeft: '24%',
            marginTop: '20%'
        }

    },

    larger: {
        [theme.breakpoints.down('sm')]: {
            color: "white",
            fontWeight: "bolder"

        }, [theme.breakpoints.up('lg')]: {
            display: "none"
        }

    },
    smaller: {
        [theme.breakpoints.down('sm')]: {
            display: "none",


        }, [theme.breakpoints.up('lg')]: {
            color: "white"
        }

    },

    image: {
        position: 'relative',
        height: 300,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 0,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },

    root: {

        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        width: '100%',
        height: '100%',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },


    caro: {

        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.up('xs')]: {
            height: 240,
            paddingTop: '15%'
        },
        [theme.breakpoints.up('sm')]: {
            height: 250,
            paddingTop: '15%'

        }
    }
}));



function Example(props){
      AOS.init({
    // initialise with other settings
    duration : 700
  });
//    const classes = useStyles();
    
const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    var items = [
        {
            name: "CONTACT ME",
            image: Para1,
            description: "we will respond to you as soon as possible"
        },
    ]

    const classes = useStyles();

    const [mainpassword, setmainpassword] = React.useState("")
    const [confirmpassword, setconfirmpassword] = React.useState("")
    const [match, setmatch] = React.useState(true)
    const [loading, setloading] = React.useState(false)
    const [loader, setloader] = React.useState(true)
    const queryParams = new URLSearchParams(window.location.search);
    const idmain = queryParams.get('credence1')
    const idpassword = queryParams.get('credence2')

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };


    const submitting = (e) => {
        setloading(true)
        e.preventDefault()

        const passwordnew = document.getElementById("password1").value
        console.log("submit")
    
        const buyingpolicy = async () => {
            await axios.post("http://localhost:3001/api/schools/resetpasswordmain/",  {
             
            _id:idmain,
            passwordold:idpassword,
            passwordnew:passwordnew

            }).then(res => {

                if (res.status === 200) {
                    setloading(!true)
                    swal("", "Password has been Reset successfully ", "success");

                } else if(res.status===201){
                    setloading(!true)
                    swal("","This serssion is not active, reset password again", "error");

                } else if(res.status===206){
                    setloading(!true)
                    swal("","You do not have access", "error");

                } else {
                    setloading(!true)
                   swal("","Password reset error ", "error");
                }



            })

        }
if(mainpassword===confirmpassword){
    try{
        buyingpolicy()

    }catch(err){
        setloading(!true)
        swal("","Password reset error ", "error");
    }
}else{
    setmatch(false)
}

    }





    useEffect(()=>{
        console.log(idmain)
console.log(idpassword)
console.log(queryParams)
if((idmain==="" && idpassword==="") ||(idmain===null && idpassword===null) ){

    setOpen(true)

}else{

    setOpen(false)

}
    },[loader])

    return (
        <div>


<Dialog
        fullScreen={fullScreen}
        open={open}
        // onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Access Denied"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{textAlign:"center"}}>
<LockIcon style={{fontSize:"500%"}}/>
            </div>
        You do not have access to this service
          </DialogContentText>
        </DialogContent>
        <DialogActions>
       
        </DialogActions>
      </Dialog>


            <Carousel swipe={true} indicators={false} touch={true} animation={""} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>

    
 
            <h2 className="MuiTypography-h4"  style={{marginTop:40}} data-aos={"fade-in"}>Reset Password</h2>
            <div align="center"><hr data-aos="fade-left" style={{textAlign:"center", width:"10%", marginTop:10, marginBottom:40,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>

          

<Grid container spacing={2} >

<Grid lg={4} xs={12} md={4}></Grid>

<Grid lg={4} xs={12} md={4} >


<form onSubmit={submitting}>
              

              <div align="center" style={{ padding: 10, borderRadius: "10px", borderColor: "#062333", borderWidth: "0.05px" }}>

              
                      <FormControl align="left" style={{ margin: 20, width:"90%" }}>
                      <b>New Password </b>
                      <TextField

                          id="password1"
                          style={{ width:"100%" }}
                          type="password"
                          placeholder="Enter Email"
                          size="small"
                          variant="outlined"

                          onChange={(e)=>setmainpassword(e.target.value) && setmatch(true)}

                      /> </FormControl>
                      <br/>

                 
                      <FormControl align="left" style={{ margin: 20, width:"90%" }}>
                      <b>Confirm Password </b>
                      <TextField

                          id="password2"
                          style={{ width:"100%" }}
                          type="password"
                          placeholder="Confirm Password "
                          size="small"
                          variant="outlined"

                          onChange={(e)=>setconfirmpassword(e.target.value) && setmatch(true)}


                      /> </FormControl>
                      <br/>

                 

                      <FormControl style={{ margin: 20, width:"90%" }}>
                  <div align="center">
                      {!loading ?
                          <input type="submit" value="SUBMIT" style={{ padding: 13.5, cursor: "pointer", borderRadius: 5, outline: "none", border: "none", backgroundColor: "#062333", color: "white", width: "100%", marginTop: "10px", marginBottom: "40px" }} />
                          :
                        //   <Button size="large" disabled startIcon={<CircularProgress style={{ color: "#062333", width: "20px", height: "20px" }} />} style={{ backgroundColor: "lightgrey", color: "#062333", width: "35ch", marginTop: "10px", marginBottom: "8px" }} >Loading...</Button>
                          <Button size="large" disabled style={{ backgroundColor: "lightgrey", color: "#062333", width: "100%", marginTop: "10px", marginBottom: "8px" }} >Loading...</Button>
                      }
                  </div>
                  </FormControl>
              </div>
              <br />
              <br />
              <br />
       

  </form>



</Grid>

<Grid lg={4} xs={12} md={4}></Grid>


</Grid>


    </div>

      
    )
}



function Item(props)



{
    const classes=useStyles()
    return (
        
        
<div>

<div style={{position:"absolute", zIndex:1, width:"100%"}}>
<Grid container spacing={5} >
<Grid item  xs={6} sm={6} lg={6} md={6} style={{ marginTop:"0%"}}>

                       
                        <div id="App"  data-aos={"flip-right"}   style={{ color: "white", fontSize: "33px", marginTop: 10, position: "relative", zIndex: 1 }}>
                            <TiltPhaseSix
                                options={{}}

                            >
                                <TiltPhaseSix
                                    options={options}
                                >
                                    <img src={User} className="imager" alt="" />
                                </TiltPhaseSix>
                            </TiltPhaseSix>

                           

                           

                            {/* <Button style={{ backgroundImage: "linear-gradient(315deg, #ffb700 0%,  #1760d3 74%)", color: "white", zIndex: 1 }} className={classes.smaller}>
                                Download CV
                            </Button> */}
                        </div>
                        

</Grid>

<Grid item  xs={6} sm={6} lg={5} md={6}  >

<div style={{ position: "relative", zIndex: 1, marginTop: "27%",}}>
<div align="center"><hr data-aos="fade-right" style={{textAlign:"center", width:"70%", marginTop:20, marginBottom:10,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>

<h1 className={classes.smaller} style={{color:"white", fontWeight:"bolder"}}>
    PASSWORD RESET <br/>
  <small style={{fontSize:"18px", marginTop:"-25px"}}>Reset Your Password Here</small>

</h1>
<p className={classes.larger} style={{color:"white", fontWeight:"bolder"}}>
  <small style={{fontSize:"16px", marginTop:"-25px"}}>Rset Your Password Here</small>

</p>
<div align="center"><hr data-aos="fade-left" style={{textAlign:"center", width:"70%", marginTop:10, marginBottom:40,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>


                     
</div>
</Grid>

</Grid>


          
</div>






<Particles className="particle" style={{position:"",zIndex:222, height:"100%", width:"200%", backgroundColor:"red" }}
        id="tsparticle"
        options={{
          background: {
            color: {
              value: "",
            },
        
          },

          fpsLimit: 30,
          interactivity: {
            detectsOn: "canvas",
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: false,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 0.5,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 10,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
            particles: {
                color: {
                    value: ["#1760d3", "#1760d3", "#1760d3"]
                },
                links: {
                    color: "#1760d3",
                    distance: 150,
                    enable: true,
                    opacity: 0.2,
                    width: 0.8,
                },
                collisions: {
                    enable: false,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: true,
                    speed: 3,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        value_area: 900,
                    },
                    value: 100,
                },
                opacity: {
                    value: 0.2,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    random: true,
                    value: 5,
                },
            },
          detectRetina: true,
        }}


      />

   </div>  

    )
}



export default Example


