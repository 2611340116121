import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Image from './img/Engaging-an-AC-Compressor-Clutch.jpg'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid';
import Para1 from './img/img/img9.jpeg';
import Cvmain from "./cv_joseph.pdf"
import Port from "./port"
import Web from './img/logo/FriendlyandResponsiveLogos.png';
import Mobile from './img/logo/mobile_app.png';
import Dbase from './img/logo/dbase.jpg';
import Consult from './img/logo/consult.png';
import Atiefoo from './img/port/atiefo.PNG';
import Zf1 from './img/port/zf1.PNG';
import Zf12 from './img/port/zf12.PNG';
import Z from './img/port/z.PNG';
import Emador from './img/port/emador.PNG';
import Training from './img/training.gif';
import Database from './img/database.gif';
import Mobileapp from './img/logo/app-data-animate.svg';
import Parentsp from './img/logo/parentsp.svg';
import Website from './img/logo/shared-workspace-animate.svg';
import AOS from 'aos';
import "aos/dist/aos.css";
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Public from '@material-ui/icons/Public';
import GitHub from '@material-ui/icons/GitHub';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import ListItemAvatar from '@mui/material/ListItemAvatar';

import AlldoneIcon from '@material-ui/icons/DoneAll';

import Particles from 'react-tsparticles'
import TiltPhaseSix from './TiltPhaseSix';
import User from './img/logo/all-the-data-animate.svg';
import shadows from '@material-ui/core/styles/shadows';







const options = {
    max: 5,
    perspective: 700,
    scale: 1.05,
}

const useStyles = makeStyles((theme) => ({

    large: {
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(20),
            height: theme.spacing(20),
            marginLeft: '20%'

        }, [theme.breakpoints.up('lg')]: {
            width: theme.spacing(43),
            height: theme.spacing(43),
            marginLeft: '24%',
            marginTop: '20%'
        }

    },

    larger: {
        [theme.breakpoints.down('sm')]: {
            color: "white",
            fontWeight: "bolder"

        }, [theme.breakpoints.up('lg')]: {
            display: "none"
        }

    },
    smaller: {
        [theme.breakpoints.down('sm')]: {
            display: "none",


        }, [theme.breakpoints.up('lg')]: {
            color: "white"
        }

    },

    image: {
        position: 'relative',
        height: 300,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 0,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },

    root: {

        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        width: '100%',
        height: '100%',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },



    caro: {

        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.up('xs')]: {
            height: 240,
            paddingTop: '15%'
        },
        [theme.breakpoints.up('sm')]: {
            height: 550,
            paddingTop: '15%'

        }
    }
}));




function Example(props) {
    AOS.init({
        // initialise with other settings
        duration: 700
    });
    //    const classes = useStyles();
    const classes = useStyles();


    var items = [
        {
            name: "My Portfolio",
            image: Para1,
            description: "We provide the best biomass wood pellet"
        }
    ]

    return (
        <div>
            <Carousel swipe={true} indicators={false} touch={true} animation={""} >
                {
                    items.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>

         
         <div>
          <br/>
          <br/>
      
                      <h2 className="MuiTypography-h5" style={{marginTop:"-20px"}}> Discover Our Solutions</h2>
                      <div align="center"><hr data-aos="fade-right" style={{textAlign:"center", width:"15%", marginTop:10, marginBottom:10, backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>



<Grid container>
    {/* <Box sx={{boxShadow:3}}> */}
    <Grid lg={6} xs={12} style={{backgroundColor:"white",}} > <div>  <img data-aos="fade-in" src={Website} width="70%" style={{marginTop:"10%"}} /> </div></Grid>
    <Grid lg={6} xs={12} style={{padding:40}} align="Right">     <CardContent data-aos="fade-in" >
                            <h2 className="MuiTypography-h5" style={{marginTop:"-20px"}}>School's Administrative Software</h2>
                            <div align="Right"><hr data-aos="fade-right" style={{textAlign:"center", width:"45%",  marginTop:10, marginBottom:10, backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>
                               
                                <Typography variant="body2" color="white" component="p"  align="left">


We provide the best and flexible school Adminstraive software to help manage schools finances, students records, staff records and improve interaction between the school and parents. SIS Revolution has the following altramordern features.

<List dense={true}>
              
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Management of student personal information"
                  // secondary={'Secondary text'}
                />
              </ListItem>   
              
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary=" Management of student academic records with flexibility"
                  // secondary={'Secondary text'}
                />
              </ListItem>  
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Generates academic reports instantly"
                  // secondary={'Secondary text'}
                />
              </ListItem> 
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary=" Management of financial records, including school fees, and other charges"
                  // secondary={'Secondary text'}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary=" Management of school expenses"
                  // secondary={'Secondary text'}
                />
              </ListItem> 
              
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Mark and keeping class attendance records"
                  // secondary={'Secondary text'}
                />
              </ListItem> 

              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary=" Management of staff data"
                  // secondary={'Secondary text'}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Management of staff's salary records and pay slip"
                  // secondary={'Secondary text'}
                />
              </ListItem>
          
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Academic timetable management"
                  // secondary={'Secondary text'}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Send academic records to guardians via text messages and email instantly"
                  // secondary={'Secondary text'}
                />
              </ListItem>
          
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary=" Send a single message to guardians via text messages and email"
                  // secondary={'Secondary text'}
                />
              </ListItem>  
              
          
              
                <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Send bulk messages to guardians via email or text messages (sms)"
                  // secondary={'Secondary text'}
                />
              </ListItem>

                <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Automatically notify guardians on ward's absenteeism"
                  // secondary={'Secondary text'}
                />
              </ListItem>
          
                <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Send instant notification to guardians of defaulters"
                  // secondary={'Secondary text'}
                />
              </ListItem>

                <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Staff chat room"
                  // secondary={'Secondary text'}
                />
              </ListItem>

                <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Staff announcement delivery"
                  // secondary={'Secondary text'}
                />
              </ListItem>

                <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Lesson notes management"
                  // secondary={'Secondary text'}
                />
              </ListItem>
          
          </List>
       
     


                                </Typography>
                            </CardContent></Grid>
                            {/* </Box> */}
</Grid>

<Container>
<Divider/>
<br/>
</Container>

<Grid container>
 <Grid lg={6} xs={12} style={{padding:40}} align="left">     <CardContent data-aos="fade-in" >
                            <h2 className="MuiTypography-h5" style={{marginTop:"-20px"}}>Teacher's Mobile Application</h2>
                            <div align="left"><hr data-aos="fade-right" style={{textAlign:"center", width:"35%", marginTop:10, marginBottom:10, backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>
                           
                           
                            <Typography variant="body2" color="white" component="p"  align="left">


We have the best and flexible Teachers software to help students records, prepare lesson notes, timetable generation and improve interaction between the teachers and parents. The Teachers’ App has the following altramordern features.

<List dense={true}>
              
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Management of student personal information"
                  // secondary={'Secondary text'}
                />
              </ListItem>   
              
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary=" Management of student academic records with flexibility"
                  // secondary={'Secondary text'}
                />
              </ListItem>  
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Generates academic reports instantly"
                  // secondary={'Secondary text'}
                />
              </ListItem> 
            
              
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Mark and keeping class attendance records"
                  // secondary={'Secondary text'}
                />
              </ListItem> 

          
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Academic timetable management"
                  // secondary={'Secondary text'}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Send academic records to guardians via text messages and email instantly"
                  // secondary={'Secondary text'}
                />
              </ListItem>
          
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary=" Send a single message to guardians via text messages and email"
                  // secondary={'Secondary text'}
                />
              </ListItem>  
            
                <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Send bulk messages to guardians via email or text messages (sms)"
                  // secondary={'Secondary text'}
                />
              </ListItem>

                <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Automatically notify guardians on ward's absenteeism"
                  // secondary={'Secondary text'}
                />
              </ListItem>
          
          
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Staff chat room"
                  // secondary={'Secondary text'}
                />
              </ListItem>

                <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Staff announcement delivery"
                  // secondary={'Secondary text'}
                />
              </ListItem>

                <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Lesson notes management"
                  // secondary={'Secondary text'}
                />
              </ListItem>
               
          </List>
       
     


                                </Typography>
                            
                            
                            </CardContent></Grid>


    <Grid lg={6} xs={12} style={{backgroundColor:"white",}} >  <div> <img data-aos="fade-in"  src={Mobileapp} width="70%" style={{marginTop:"0%"}} /> </div>  </Grid>
   
</Grid>


<Grid container>
<Grid lg={6} xs={12} style={{backgroundColor:"white",}} >  <div> <img data-aos="fade-in"  src={Parentsp} width="70%" style={{marginTop:"0%"}} /> </div>  </Grid>

 <Grid lg={6} xs={12} style={{padding:40}} align="left">     <CardContent data-aos="fade-in" >
                            <h2 className="MuiTypography-h5" style={{marginTop:"-20px"}}>Parents' Portal</h2>
                            <div align="left"><hr data-aos="fade-right" style={{textAlign:"center", width:"35%", marginTop:10, marginBottom:10, backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>
                           
                           
                            <Typography variant="body2" color="white" component="p"  align="left">


We offer the best and flexible Parents Portal help access thier ward's educational information.

<List dense={true}>
              
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Access Student Academic Records"
                  // secondary={'Secondary text'}
                />
              </ListItem>   
              
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Acess Student Bill"
                  // secondary={'Secondary text'}
                />
              </ListItem>  
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Access School Fees Payment Records"
                  // secondary={'Secondary text'}
                />
              </ListItem> 
            
              
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Receive notification from school and teachers"
                  // secondary={'Secondary text'}
                />
              </ListItem> 

          
              <ListItem>
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Track Class Assignments"
                  // secondary={'Secondary text'}
                />
              </ListItem>

          </List>
       
     


                                </Typography>
                            
                            
                            </CardContent></Grid>


   
</Grid>



         </div>

        </div>


    )
}





function Item(props)



{
    const classes=useStyles()
    return (
        
        
<div>

<div style={{position:"absolute", zIndex:1, width:"100%"}}>
<Grid container spacing={5} >
<Grid item  xs={12} sm={12} lg={6} md={6} style={{ marginTop:"0%"}}>

                       
                        <div id="App"  data-aos={"flip-right"}   style={{ color: "white", fontSize: "33px", marginTop: 5, position: "relative", zIndex: 1 }}>
                            <TiltPhaseSix
                                options={{}}

                            >
                                <TiltPhaseSix
                                    options={options}
                                >
                                    <img src={User} className="imager" alt="" />
                                </TiltPhaseSix>
                            </TiltPhaseSix>

                           

                           

                            {/* <Button style={{ backgroundImage: "linear-gradient(315deg, #ffb700 0%,  #1760d3 74%)", color: "white", zIndex: 1 }} className={classes.smaller}>
                                Download CV
                            </Button> */}
                        </div>
                        

</Grid>

<Grid item  xs={12} sm={12} lg={5} md={6}  >

<div style={{ position: "relative", zIndex: 1, marginTop: "28%",}}>
<div align="center"><hr data-aos="fade-right" style={{textAlign:"center", width:"82%", marginTop:"-80px", marginBottom:10,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>

<h1 className={classes.smaller} style={{color:"white", fontWeight:"bolder"}}>
OUR SOLUTION<br/>
<small style={{fontSize:"16px", marginTop:"-25px"}}>Discover How We Can Help You</small>

</h1>
<p className={classes.larger} style={{color:"white", fontWeight:"bolder"}}>
  <small style={{fontSize:"16px", marginTop:"-25px"}}>Discover How We Can Help You</small>

</p>
<div align="center"><hr data-aos="fade-left" style={{textAlign:"center", width:"82%", marginTop:10, marginBottom:40,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>


                     
</div>
</Grid>

</Grid>


          
</div>



<div className="particle" >


<Particles 
id="tsparticle"
        options={{
          background: {
            color: {
              value: "",
            },
        
          },

          fpsLimit: 30,
          interactivity: {
            detectsOn: "canvas",
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: false,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 0.5,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 10,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
            particles: {
                color: {
                    value: ["#1760d3", "#1760d3", "#1760d3"]
                },
                links: {
                    color: "#1760d3",
                    distance: 150,
                    enable: true,
                    opacity: 0.2,
                    width: 0.8,
                },
                collisions: {
                    enable: false,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: true,
                    speed: 3,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        value_area: 900,
                    },
                    value: 100,
                },
                opacity: {
                    value: 0.2,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    random: true,
                    value: 5,
                },
            },
          detectRetina: true,
        }}


      />

   </div>  
   </div>
    )
}


export default Example


