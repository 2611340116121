import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

//import Call from '@material-ui/icons/Call';
//import Email from '@material-ui/icons/Email';
import RoomIcon from '@material-ui/icons/Room';
import Para1 from './img/contact1.jpg';
import Leave from './leave'
import AOS from 'aos';
import "aos/dist/aos.css";
import Email from '@material-ui/icons/Email';
import Call from '@material-ui/icons/Call';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import Twitter from '@material-ui/icons/Twitter';
import GitHub from '@material-ui/icons/GitHub';
import Particles from 'react-tsparticles' 

import Cvmain from "./cv_joseph.pdf"

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AlarmIcon from '@material-ui/icons/Alarm';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import TiltPhaseSix from './TiltPhaseSix';
import User from './img/logo/service-247-animate.svg';
const options = {
    max: 5,
    perspective: 700,
    scale: 1.05,
}

const useStyles = makeStyles((theme) => ({

    large: {
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(20),
            height: theme.spacing(20),
            marginLeft: '20%'

        }, [theme.breakpoints.up('lg')]: {
            width: theme.spacing(43),
            height: theme.spacing(43),
            marginLeft: '24%',
            marginTop: '20%'
        }

    },

    larger: {
        [theme.breakpoints.down('sm')]: {
            color: "white",
            fontWeight: "bolder"

        }, [theme.breakpoints.up('lg')]: {
            display: "none"
        }

    },
    smaller: {
        [theme.breakpoints.down('sm')]: {
            display: "none",


        }, [theme.breakpoints.up('lg')]: {
            color: "white"
        }

    },

    image: {
        position: 'relative',
        height: 300,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 0,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },

    root: {

        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        width: '100%',
        height: '100%',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },



    caro: {

        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.up('xs')]: {
            height: 240,
            paddingTop: '15%'
        },
        [theme.breakpoints.up('sm')]: {
            height: 550,
            paddingTop: '15%'

        }
    }
}));



function Example(props){
      AOS.init({
    // initialise with other settings
    duration : 700
  });
//    const classes = useStyles();
    
    var items = [
        {
            name: "CONTACT ME",
            image: Para1,
            description: "we will respond to you as soon as possible"
        },
    ]

    const classes = useStyles();

    return (
        <div>
            <Carousel swipe={true} indicators={false} touch={true} animation={""} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>

    
 
            <h2 className="MuiTypography-h4"  style={{marginTop:15}} >Get In Touch</h2>
            <div align="center"><hr data-aos="fade-left" style={{textAlign:"center", width:"10%", marginTop:10, marginBottom:40,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>

            <br/>
            <p data-aos={"fade-up"}>
                We can bring your ideas to life. Talk to us today about your dream
                website and mobile application for your business and lets build together
            </p>
            <br />
<br/>
<br/>

        <Grid container spacing={0} >
        <Grid item xs={12} sm={6} md={6}  >

        <h3 className="MuiTypography-h5" style={{marginTop:'20px',textAlign:'center'}} data-aos={"fade-up"}>Our Contact</h3>


                    <br/>
                 


           <Grid item  xs={12} sm={12} md={12} >
        
        <Grid container spacing={1} style={{textAlign:'center'}}>
         
<Grid item xs={2} sm={2} md={2} style={{textAlign:'right', paddingBottom:'0px'}}data-aos={"fade-up"}>
        <Email/>
         </Grid>
        <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}}data-aos={"fade-up"}>
        <a href='mailto:Support@sisrevolution.com'>support@sisrevolution.com</a>
         </Grid>         

    <Grid item xs={2} sm={2} md={2} style={{textAlign:'right', paddingBottom:'0px'}} data-aos={"fade-up"}>
        <Call/>
         </Grid>
        <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
        <a href='tel:+233 550542370'>+233 550542370 </a>
         </Grid>





                            <Grid item xs={2} sm={2} md={2} style={{ textAlign: 'right', paddingBottom: '0px' }} data-aos={"fade-up"}>
                                <RoomIcon />
         </Grid>
        <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
        <a>Bomso Opposite MTN Office, Close to Lizy's Hotel, Kumasi, Ghana</a>

                                {/* <a href='#'>22 Skyvalley, Brampton ON L6P 2N1, Canada <br/>Bomso-Kumasi, Ghana</a> */}
         </Grid>
                            
                            
                            <Grid item xs={2} sm={2} md={2} style={{ textAlign: 'right', paddingBottom: '0px' }} data-aos={"fade-up"}>
                                <Facebook />
         </Grid>
        <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
                                <a href='https://web.facebook.com/ravinlead.techgh/'>RavinLead Tech Hub</a>
         </Grid>

                 
                            <Grid item xs={2} sm={2} md={2} style={{ textAlign: 'right', paddingBottom: '0px' }} data-aos={"fade-up"}>
                                <LinkedIn/>
         </Grid>
        <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
                                <a href='https://www.linkedin.com/company/ravinlead-tech-hub/'>RavinLead Tech Hub</a>
         </Grid>



        </Grid>


        </Grid>
        
        <br/>
<br/>


         </Grid> 

        <Grid item xs={12} sm={6} md={6} style={{textAlign:'left', padding:15}} data-aos={"fade-up"}>

        <h3 className="MuiTypography-h5" style={{marginTop:'10px',textAlign:'center'}} data-aos={"fade-up"}>Leave A Message</h3>
        <p className="MuiTypography-h6" style={{marginTop:'-29px'}}></p>
<br/>
<br/>
<br/>
            <p style={{marginBottom:80}}>
           <Leave/>
            </p>
            
            
            
         </Grid>
        </Grid>
            


    </div>

      
    )
}



function Item(props)



{
    const classes=useStyles()
    return (
        
        
<div>

<div style={{position:"absolute", zIndex:1, width:"100%"}}>
<Grid container spacing={5} >
<Grid item  xs={12} sm={12} lg={6} md={6} style={{ marginTop:"0%"}}>

                       
                        <div id="App"  data-aos={"flip-right"}   style={{ color: "white", fontSize: "33px", marginTop: 10, position: "relative", zIndex: 1 }}>
                            <TiltPhaseSix
                                options={{}}

                            >
                                <TiltPhaseSix
                                    options={options}
                                >
                                    <img src={User} className="imager" alt="" />
                                </TiltPhaseSix>
                            </TiltPhaseSix>

                           

                           

                            {/* <Button style={{ backgroundImage: "linear-gradient(315deg, #ffb700 0%,  #1760d3 74%)", color: "white", zIndex: 1 }} className={classes.smaller}>
                                Download CV
                            </Button> */}
                        </div>
                        

</Grid>

<Grid item  xs={12} sm={12} lg={5} md={6}  >

<div style={{ position: "relative", zIndex: 1, marginTop: "27%",}}>
<div align="center"><hr data-aos="fade-right" style={{textAlign:"center", width:"70%", marginTop:"-80px", marginBottom:10,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>

<h1 className={classes.smaller} style={{color:"white", fontWeight:"bolder"}}>
    SIS REVOLUTION <br/>
  <small style={{fontSize:"18px", marginTop:"-25px"}}>Revolutionizing The Education Systems</small>

</h1>
<p className={classes.larger} style={{color:"white", fontWeight:"bolder"}}>
  <small style={{fontSize:"16px", marginTop:"-25px"}}>Revolutionizing The Education Systems</small>

</p>
<div align="center"><hr data-aos="fade-left" style={{textAlign:"center", width:"70%", marginTop:10, marginBottom:40,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>


                     
</div>
</Grid>

</Grid>


          
</div>



<div className="particle" >

<Particles
id="tsparticle"
options={{
          background: {
            color: {
              value: "",
            },
        
          },

          fpsLimit: 30,
          interactivity: {
            detectsOn: "canvas",
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: false,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 0.5,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 10,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
            particles: {
                color: {
                    value: ["#1760d3", "#1760d3", "#1760d3"]
                },
                links: {
                    color: "#1760d3",
                    distance: 150,
                    enable: true,
                    opacity: 0.2,
                    width: 0.8,
                },
                collisions: {
                    enable: false,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: true,
                    speed: 3,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        value_area: 900,
                    },
                    value: 100,
                },
                opacity: {
                    value: 0.2,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    random: true,
                    value: 5,
                },
            },
          detectRetina: true,
        }}


      />

   </div>  
   </div>

    )
}



export default Example


