import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Button, Divider } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Para0 from './img/code/HD-wallpaper-code-programming-monitor-hacker.jpg';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import AOS from 'aos';
import Joseph from "./img/joseph.jpg"
import Kofi from "./img/kofi.jpg"
import Osei from "./img/osei.jpg"
import Grid from '@material-ui/core/Grid';
import TiltPhaseSix from './TiltPhaseSix';
import User from './img/logo/discussion-animate.svg';



import Particles from 'react-tsparticles'
import "aos/dist/aos.css";
import Cvmain from "./cv_joseph.pdf"


const options = {
    max: 5,
    perspective: 700,
    scale: 1.05,
}

const useStyles = makeStyles((theme) => ({

    large:{
        
        [theme.breakpoints.up('xs')]: {
         width: theme.spacing(26),
         height: theme.spacing(26),
            marginLeft:'20%',
            marginBottom:"10px"
         },
        [theme.breakpoints.up('sm')]: {
         width: theme.spacing(26),
         height: theme.spacing(26),
            marginLeft:'13%',
            marginBottom:"10px"
         }
    
   },
  

    larger: {
        [theme.breakpoints.down('sm')]: {
            color: "white",
            fontWeight: "bolder"

        }, [theme.breakpoints.up('lg')]: {
            display: "none"
        }

    },
    smaller: {
        [theme.breakpoints.down('sm')]: {
            display: "none",


        }, [theme.breakpoints.up('lg')]: {
            color: "white"
        }

    },

    image: {
        position: 'relative',
        height: 300,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 0,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },

    root: {

        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        width: '100%',
        height: '100%',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },



    caro: {

        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.up('xs')]: {
            height: 240,
            paddingTop: '15%'
        },
        [theme.breakpoints.up('sm')]: {
            height: 550,
            paddingTop: '15%'

        }
    }
}));



function Example(props) {
 





    return (

        <div>



  
<Container>

<header>
    <br/>
 <h2>TERMS OF USE FOR THE SIS REVOLUTION </h2>
 </header>
 <br/>
<Divider/>
<br/>
<article>


 <section>

  <h2 id="use">USE</h2>

   <p id="misc_children">
     SIS Revolution is a school database management system, specially built to digitize the activities of a
schools. The relevance of SIS Revolution is dependent on the ability to make life easier for schools by
providing reliable system for them to manage their financial records, student records, staff records, easy
access to parents. The information presented herein is for informative purposes only. We invite Users to
visit the Platform and use the information contained therein for the User's personal, non-commercial use,
without any right to resell or redistribute its content for commercial purposes, subject to the terms and
conditions outlined below. Use of this Platform constitutes agreement with the following terms and
conditions
   </p>

  </section>
  <br/>
  <Divider/>

 <br/>
 <section>
 
  <h2 id="disclaimer">DISCLAIMER</h2>

   <p id="collection_tracking">
     Materials provided on this Platform are provided "as is" without warranty of any kind, either express or
implied, including without limitation warranties of merchantability, fitness for a particular purpose, and
non-infringement.
Database Owners and Administrators specifically do not make any warranties or representations as to the
accuracy or completeness of any such Materials. Database Owners and Administrators periodically add,
change, improve, or update the Materials on this Platform without notice. Under no circumstances shall
the Database Owners and Administrators be liable for any loss, damage, liability or expense incurred or
suffered which is claimed to resulted from use of this Platform, including without limitation, any fault,
error, omission, interruption or delay with respect thereto.
Use of this Platform is at User's sole risk. Under no circumstances, including, but not limited to, negligence,
shall Database Owners and Administrators be liable for any direct, indirect, incidental, special or
consequential damages, even if they have been advised of the possibility of such damages. User
specifically acknowledges and agrees that the Database Owners and Administrators are not liable for any
conduct of any User. The Database Owners and Administrators do not represent or endorse the accuracy
or reliability of any advice, opinion, statement or other information provided by any information provider
or content provider, or any user of this Platform or other person or entity. Reliance upon any such opinion,
advice, statement, or other information shall also be at your own risk. Neither the Database Owners and
Administrators, nor any of their respective agents, employees, information providers or content providers
shall be liable to any User or anyone else for any inaccuracy, error, omission, interruption, timeliness,
completeness, deletion, defect, failure of performance, computer virus, communication line failure,
alteration of, or use of any content herein, regardless of cause, for any damages resulting therefrom.
As a condition of use of this Platform, User agrees to indemnify the Database Owners and Administrators
from and against any and all actions, claims, losses, damages, liabilities and expenses (including
reasonable attorneys' fees) arising out User's use of this Platform, including without limitation any claims
alleging facts that if true would constitute a breach by User of these terms and conditions. If User is
dissatisfied with any material on this Platform or with any of terms and conditions of use of this Platform,
User's sole and exclusive remedy is to discontinue using this Platform. Nothing in this Platform or any
Materials shall be construed, implicitly or explicitly, as containing any investment recommendations.
Accordingly, nothing in such Platform, or in such Materials, constitutes an offer of or an invitation by or
on behalf of the Database Owners and Administrators to use, purchase or sell any of the technologies and
solutions mentioned, nor should it be considered as investment advice.
   </p>

  </section> <br/>
  <Divider/>
 <br/>

 <section>

  <h2 id="protect">HOW DO WE PROTECT AND SAFEGUARD YOUR INFORMATION?</h2>
  <p>  The Database Owners and Administrators, employ a range of technologies to protect the information
     maintained on our systems from loss, misuse, unauthorized access or disclosure, alteration, or
     destruction</p>

  </section>
 <br/>
<Divider/>
 <br/>
 <section>

  <h2 id="verify">HOW CAN YOU VERIFY, MODIFY OR DELETE YOUR INFORMATION?</h2>

   <p id="disclosure_partners">In case you wish to verify which personal data is stored on your behalf in the Platform, have it modified,
     corrected, or deleted, please make use of the contact information for the Administrators mentioned in
     the Platform, and by explicitly describing your request. Any correction of your personal data will be taken
     into consideration from the data protection point of view.</p>


  </section> <br/>
<Divider/> <br/>


 <section>

  <h2 id="long">HOW LONG DO WE KEEP YOUR PERSONAL DATA?</h2>

   <p id="">Your personal data are kept for the existence of the Platform, and in the archives for a period of 5 years
     following the end of the Platform</p>


  </section> <br/>
<Divider/> <br/>


 <section>

  <h2 id="payment">PAYMENT TERMS </h2>

   <p id="">Participants are responsible for fees upon the start of using the system. Participants only pays using
     monthly subscriptions. Users of the moboile applications does not make payments, except for the users of the adinistrative application at the school administration.
     </p>

  </section> <br/>
<Divider/> <br/>

 <section>

  <h2 id="monthly">MONTHLY PAYMENTS </h2>

   <p id="misc_choice">Monthly payments are paid by users on the main Desktop adminstrative application and not on the mobile application. Unless otherwise specified
     in a Subscription, such fees will be billed in advance for each month and are non-refundable. We reserve
     the right to modify our billing rates at any time upon thirty (30) days written notice by posting these fee
     changes to the Site or by notifying you. Should participants continue to use the Services after these
     changes go into effect, they will be responsible for paying the new Subscription/billing rate.
     </p>

  </section> <br/>
  <Divider/> <br/>
 <section>

  <h2 id="account">ACCOUNT SET-UP</h2>

   <p id="misc_choice">After signing up, participants will be sent an email upon how to setup a subscription account on the
     system. </p>

  </section> <br/>
<Divider/> <br/>

 <section>

  <h2 id="subscribe">SUBSCRIPTION CANCELATION
 </h2>

   <p id="misc_choice">If participant wants to cancel their agreement with us, they should send an email to our office indicating
     reasons for cancelation and they will be taken off the database. </p>

  </section>

 </article>

</Container>







        </div>


    )
}





export default Example


