import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';

import AlldoneIcon from '@material-ui/icons/DoneAll';
import axios from 'axios'
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '47%',
    },
  },
}));

export default function MultilineTextFields() {
  const classes = useStyles();
  const [loading, setloading]= React.useState(false)
  const [stated, setstated] = React.useState("")
  const [message, setmessage] = React.useState("")


  const addmessage = (e) => {
    e.preventDefault()

    setloading(true)
    const lastname = document.getElementById("lastname").value
    const othernames = document.getElementById("othernames").value
    const contact = document.getElementById("contact").value
    const email = document.getElementById("email").value
    const message = document.getElementById("message").value



    const fd = new FormData()

    fd.append("lastname", lastname)
    fd.append("othernames",othernames)
    fd.append("contact", contact)
    fd.append("email", email)
    fd.append("message", message)

   
    const uploading = async() => {
      try{
        await axios.post("https://servermain.sisrevolution.com/api/school/contactus/", fd, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }


        }).then(res => {

            if (res.status === 200) {

              swal("Successful", "Message sent", "success"); 
              setloading(!true)

            }
            if (res.status === 204) {
              swal("Error", "Error sending message", "error");

                setloading(!true)

            }

        })
      }catch(err){
        swal("Network Error", "Check your network connection", "warning");

setloading(!true)
      }

    }

try{
    uploading()
}catch{
  swal("Network Error", "Check your network connection", "error");

setloading(!true)

}


}


  

  return (
    <form onSubmit={addmessage} className={classes.root} >
     
     
      <div>
        
        <TextField
          id="lastname"
          label="Lastname"
          type="text"
          placeholder="Firstname"
          multiline
          required
          variant="outlined"
        />
        <TextField
          id="othernames"
          label="Othernames"
          placeholder="Lastname"
          multiline
          type="text"
          required
          variant="outlined"
        />
       </div> 
      <div>
        <TextField
          id="email"
          label="Email"
          type="email"
          required
          placeholder="Email"
          multiline
          variant="outlined"
        />
        <TextField
          id="contact"
          label="Contact"
          placeholder="Contact"
          multiline
          required

          variant="outlined"
       type="tel"
        />
        <TextField
          id="message"
          label="Message"
          multiline
          required
          type="text"

          rows={4}
           placeholder="Enter Message"
          variant="outlined"
      style={{width:"97%"}}
        />
      </div>
      <div style={{textAlign:"center"}}>
        {/* <input type='submit' style={{border:"none", padding:"10px", backgroundColor:"blue", color:"white"}} color="primary"  /> */}
      <Button type='submit' variant="contained" color="primary">
 Submit Message
</Button>
      </div>
    </form>
  );
}


