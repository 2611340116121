import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Image from './img/Engaging-an-AC-Compressor-Clutch.jpg'
import {makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid';
import Para1 from './img/img/img9.jpeg';
import Cvmain from "./cv_joseph.pdf"
import Port from "./port"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Atiefoo from './img/port/atiefo.PNG';
import Signupfree from './img/signupfree.svg';
import TC from './img/sisrevolution T&C.pdf';
import Zf1 from './img/port/zf1.PNG';
import Zf12 from './img/port/zf12.PNG';
import Z from './img/port/z.PNG';
import Emador from './img/port/emador.PNG';
import AM from './img/port/3am.PNG';
import Jsk from './img/port/jsk2.PNG';
import Shop from './img/port/shopon.PNG';
import Book from './img/port/book.PNG';
import Portmain from './portmain';
import AOS from 'aos';
import "aos/dist/aos.css";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Public from '@material-ui/icons/Public';
import GitHub from '@material-ui/icons/GitHub';
import Imager from './img/logo/Wallet.svg'
import Android from './img/logo/android.png'
import Windows from './img/logo/windows.png'
import Apple from './img/logo/apple.png'
import Particles from 'react-tsparticles' 
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
// import ListItemAvatar from '@mui/material/ListItemAvatar';
import swal from 'sweetalert';
import SIS from './SISRevolution.exe';

import AlldoneIcon from '@material-ui/icons/DoneAll';
import axios from 'axios'
import TiltPhaseSix from './TiltPhaseSix';
import User from './img/logo/tablet-login-animate.svg';
import { useEffect } from 'react';
const options = {
    max: 5,
    perspective: 700,
    scale: 1.05,
}

const useStyles = makeStyles((theme) => ({

    large: {
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(20),
            height: theme.spacing(20),
            marginLeft: '20%'

        }, [theme.breakpoints.up('lg')]: {
            width: theme.spacing(43),
            height: theme.spacing(43),
            marginLeft: '24%',
            marginTop: '20%'
        }

    },

    larger: {
        [theme.breakpoints.down('sm')]: {
            color: "white",
            fontWeight: "bolder"

        }, [theme.breakpoints.up('lg')]: {
            display: "none"
        }

    },
    smaller: {
        [theme.breakpoints.down('sm')]: {
            display: "none",


        }, [theme.breakpoints.up('lg')]: {
            color: "white"
        }

    },

    image: {
        position: 'relative',
        height: 300,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 0,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },

    root: {

        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        width: '100%',
        height: '100%',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },



    caro: {

        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.up('xs')]: {
            height: 240,
            paddingTop: '15%'
        },
        [theme.breakpoints.up('sm')]: {
            height: 550,
            paddingTop: '15%'

        }
    }
}));

function Example(props)

{
     AOS.init({
    // initialise with other settings
    duration : 700
  });

  const classes = useStyles();
     
    const [pricevalue, setpricevaluer]= React.useState(100)
    const [amount, setamount]= React.useState(0)
    const [agree, setagree]= React.useState(false)
    const [mainvalue, setmainvalue]= React.useState(0)
    const [loading, setloading]= React.useState(false)
    const [studentpopulation, setstudentpopulation] = React.useState("none")
    const [stated, setstated] = React.useState("")
    const [Internet, setInternet] = React.useState("Yes")
    const [max, setmax]=React.useState("no")
    const [message, setmessage] = React.useState("")
    const pricing=[[50,200,450],[201,400,750], [401,600,1110],[601,800,1400], [801,1000,1700],[1001,1200,2050],[1201,1400,2350],[1401,1600,2650],[1601,1800,2950],[1801,2000,3250],[2001,2200,3550],[2201,2400,3850],[2401,2600,4150],[2601,2800,4450],[2801,3000,4750]]


  

    const submitting = (e) => {
        setloading(true)
        e.preventDefault()

        // const file = document.getElementById("fileimage").files[0]
        const name = document.getElementById("name").value
        const contact = document.getElementById("contact").value
        const email = document.getElementById("email").value
        const staffpopulation = document.getElementById("staffpopulation").value
        const address = document.getElementById("address").value
        const yearjoined = document.getElementById("yearjoined").value
        const studentpopulation_ = document.getElementById("studentpopulation_").value
      
        const fd = new FormData()
        console.log(Internet)
        //   if (file) {
                   
        //                 fd.append("file", file)
        //                 fd.append("image", document.getElementById("fileimage").files[0].name)
        //             }
                    fd.append("schoolname",name)
                    fd.append("amount",amount)
                    fd.append("contact",contact)
                    fd.append("email",email)
                    fd.append("address",address)
                    fd.append("yearestablished",yearjoined)
                    fd.append("studentpopulation",studentpopulation)
                    fd.append("studentpopulation_",studentpopulation_)
                    fd.append("staffpopulation",staffpopulation)
                    fd.append("internet",Internet)
                    
        const buyingpolicy = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/schools/registerschool/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }

                        }).then(res => {
                   console.log(res)
                            if (res.status === 200) {
                     
                        e.target.reset()
                        setloading(!true)

                        swal("Signup Successful", "Check your email for your login credentials", "success");


                    }  if (res.status === 204) {
                        setloading(!true)
                        swal("", "This School has already signed up", "warning");
                    }
                  

                })

        }
try{
        buyingpolicy()

}catch(err){
    setloading(!true)
    // swal("", "Error Signing up", "error");

}

    }



useEffect(()=>{

    setmainvalue((pricevalue*1.6).toFixed(2))

},[pricevalue])


useEffect(()=>{
 
   
var val = document.getElementById("studentpopulation_").value
            setamount((val*1.3).toFixed(2))
           
},[studentpopulation])
    
  var items = [
        {
            name: "My Portfolio",
            image: Para1,
            description: "We provide the best biomass wood pellet"
        }
      ]



        const handleInternet=(event)=>{
        setInternet(event.target.value)
    }

    return (
        <div>
            <Carousel swipe={true} indicators={false} touch={true} animation={""} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>

    <br/>
    <br/>


   <Container  maxWidth="lg" style={{textAlign:"center"}}>
    
   <h2 className="MuiTypography-h5" style={{marginTop:"-20px"}}>Get SIS Revolution For Your School</h2>
                            <div align="center"><hr data-aos="fade-right" style={{textAlign:"center", width:"25%", marginTop:10, marginBottom:10, backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>
                           
<Grid spacing={6}container>

<Grid item lg={6} sm={12} xs={12} >
    <br/>
    <br/>
{/* <h2 align="center">Pricing</h2> */}
<br/>
<div >

<div style={{paddingLeft:"4%", paddingRight:"4%"}}>
<br/>
<br/>

<Box boxShadow={3} style={{backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)", textAlign:"center", color:"white", borderRadius:"8px", padding:"8px"}}>

{/* <div>
<div align="center">


</div>
<div style={{textAlign:"center"}}>

  <img  src={Imager} width="60%" />

</div>

{
max==="no"?<h1 style={{margin:0, marginTop:"-10px", marginBottom:"10px", fontWeight:"bolder"}}> GHC {Intl.NumberFormat('en-US').format(mainvalue)} <span style={{fontSize:"14px"}}>/ month</span></h1>:<p>Quote for this value not found, contact our office</p>
}
<div align="center">

<span style={{fontWeight:"bol"}}>Number Students</span> <br/> <input onChange={(e)=>setpricevaluer(e.target.value)} type='number' placeholder='200' style={{placeholderColor:"white", backgroundColor:"rgba(255,255,255,0.4)", outline:"none", fontSize:"30px", padding:"4px", width:"120px", borderRadius:"6px", border:"solid", borderWidth:"0.1px", borderColor:"white", textAlign:"center", color:"white", marginTop:"5px"}}  />
<br/>
<br/>
</div>

<Divider/>
<div style={{textAlign:"left"}}>
  <p>
  
            <List dense={true}>
              
                <ListItem>
                  <ListItemIcon>
                 <AlldoneIcon style={{color:"lightgray"}}/>
                  </ListItemIcon>
                  <ListItemText
                  style={{marginLeft:"-20px", color:"white"}}
                    primary="Signup with SIS Revolution and pay monthly"
                    // secondary={'Secondary text'}
                  />
                </ListItem>   
                
                <ListItem>
                  <ListItemIcon>
                 <AlldoneIcon style={{color:"lightgray", }}/>
                  </ListItemIcon>
                  <ListItemText
                  style={{marginLeft:"-20px", color:"white"}}
                    primary="Pay with your Visa Card, Mastercard or Mobile Money"
                    // secondary={'Secondary text'}
                  />
                </ListItem>      
                <ListItem>
                  <ListItemIcon>
                 <AlldoneIcon style={{color:"lightgray", }}/>
                  </ListItemIcon>
                  <ListItemText
                  style={{marginLeft:"-20px", color:"white"}}
                    primary="You can choose to pay Termly or Monthly"
                    // secondary={'Secondary text'}
                  />
                </ListItem>  

                <ListItem>
                  <ListItemIcon>
                 <AlldoneIcon style={{color:"lightgray"}}/>
                  </ListItemIcon>
                  <ListItemText
                  style={{marginLeft:"-20px",color:"white"}}
                    primary="Get access to all the features of the Software"
                    // secondary={'Secondary text'}
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                 <AlldoneIcon style={{color:"lightgray"}}/>
                  </ListItemIcon>
                  <ListItemText
                  style={{marginLeft:"-20px",color:"white"}}
                    primary="Get free router for your school if you do not have internet"
                    // secondary={'Secondary text'}
                  />
                </ListItem>

             
            
            </List>
         
       
    

  </p>

<br/>


</div>
</div> */}


<div style={{textAlign:"center"}}>
    <img src={Signupfree}/> 
</div>

</Box>









</div>

</div>

</Grid>
<Grid item lg={6} sm={12} xs={12} style={{textAlign:"center"}} >
<br/>
<br/>
<h2 align="center">Register Now (For School Administration Only)</h2>
<br/>
       <Box boxShadow={3}  style={{borderRadius:"10px"}}>
                            <form align="center" onSubmit={submitting} style={{padding:"20px", textAlign:"center"}} >
                             
                                <p style={{ textAlign: "center", color: "gray" }}>Fill this form and We will get back to your school via your email</p>
                                <br/>
                                <FormControl  style={{width:"100%"}}   variant="outlined">

                                    <TextField
                                        required
                                        id="name"
                                        placeholder="Name Of School"
                                        type="text"
                                        // className="credentials"
                                        // variant="standard"
                                        size='small'   
                                        style={{width:"100%"}}
                                        variant="outlined"

                                       
                                    />

                                </FormControl>
                                <br />
                                <br />
                                <FormControl  style={{width:"100%"}}  variant="standard">

                                    <TextField
                                        required
                                        id="contact"
                                        placeholder="Contact"
                                        type="tel"
                                        className="credentials"
                                        // variant="standard"
                                        size='small'   

                                        style={{width:"100%"}}                                         variant="outlined"

                                        
                                    />
                                </FormControl>
                                <br />
                                <br />
                                <FormControl style={{width:"100%"}}   variant="standard">

                                    <TextField
                                        required
                                        id="email"
                                        placeholder="Email"
                                        type="email"
                                        className="credentials"
                                        // variant="standard"
                                        size='small'   

                                        style={{width:"100%"}}                                         variant="outlined"

                                       
                                    />
                                </FormControl>
                               
                                <br />
                                <br />

                                <FormControl style={{width:"100%"}}   variant="standard">

                                    <TextField
                                        required
                                        id="address"
                                        placeholder="School's Address"
                                        type="address"
                                        // className="credentials"
                                        // variant="standard"
                                        size='small'   

                                        style={{width:"100%"}}                                         variant="outlined"

                                    />
                                </FormControl>
                                <br />
                                <br />

                                <FormControl style={{width:"100%"}}   variant="standard">

                                    <TextField
                                        required
                                        id="yearjoined"
                                        placeholder="Year Established"
                                        type="number"
                                        className="credentials"
                                        // variant="standard"
                                        size='small'   

                                        style={{width:"100%"}}                                         variant="outlined"

                                    />
                                </FormControl><br />
                                <br />

                                <FormControl style={{width:"100%"}}   variant="standard">

                                    <TextField
                                        required
                                        id="staffpopulation"
                                        placeholder="Staff Population"
                                        type="number"
                                        className="credentials"
                                        // variant="standard"
                                        size='small'   

                                        style={{width:"100%"}}                                         variant="outlined"

                                    />
                                </FormControl>
   <br />
   <br />

                                <FormControl style={{width:"100%"}}   variant="standard">

                                    <TextField
                                        required
                                        id="studentpopulation_"
                                        placeholder="Actual Student Population"
                                        type="number"
                                        className="credentials"
                                        // variant="standard"
                                        size='small'   

                                        style={{width:"100%"}}                                         variant="outlined"

                                    />
                                </FormControl>

                                <br/>
                                <br/>
                                <FormControl style={{width:"100%"}}   >
                                <Select
                            required
                            id="students"
                            placeholder="School's Address"
                            type="address"
                            value={studentpopulation}
                            onChange={(e)=>setstudentpopulation(e.target.value)}
                           
                            size='small' 

                            style={{width:"100%", textAlign:"left"}}                             variant="outlined"
                        >
                            <MenuItem value="none"  size='small' ><em>Student Population Range</em></MenuItem>
        <MenuItem value="50-200">50-200</MenuItem>
        <MenuItem value="201-400">201-400</MenuItem>
        <MenuItem value="401-600">401-600</MenuItem>
        <MenuItem value="601-800">601-800</MenuItem>
        <MenuItem value="801-1000">801-1000</MenuItem>
        <MenuItem value="1001-1200">1001-1200</MenuItem>
        <MenuItem value="1201-1400">1201-1400</MenuItem>
        <MenuItem value="1401-1600">1401-1600</MenuItem>
        <MenuItem value="1601-1800">1601-1800</MenuItem>
        <MenuItem value="1801-2000">1801-2000</MenuItem>
        <MenuItem value="2001-2200">2001-2200</MenuItem>
 
                        </Select>
                                  <br/>

                                  <FormControl component="fieldset">
      <FormLabel component="legend">Do you have internet access in your school?</FormLabel>
      <RadioGroup required row aria-label="position" value={Internet} onChange={handleInternet} name="position" defaultValue="top">
        <FormControlLabel
        style={{marginRight:40}}
          value="Yes"
          control={<Radio color="primary" />}
          label="Yes"
          labelPlacement="right"
        />

        <FormControlLabel
          value="No"
          control={<Radio color="primary" />}
          label="No"
          labelPlacement="right"
        />
      
      </RadioGroup>
    </FormControl>



                                </FormControl>
                                 
                <br />
                                <FormControl style={{width:"100%", marginTop:"10px"}}   >

                                <FormControlLabel
        control={
          <Checkbox
          required
            checked={agree}
            onChange={()=>setagree(!agree)}
            name="checkedB"
            color="primary"
          />
        }
        label={<small style={{color:"grey"}}>I AGREE that i have read and understood the <a href={TC} download style={{color:"blue"}}>terms and conditions</a></small>}
      />

      
      </FormControl>

                                <br />

                              
                                {loading === false ?
                                    <input type="submit" value="SUBMIT" style={{ backgroundColor: " #e0eff9", color: "#1976d2", cursor: "pointer", padding: "15px", borderRadius: "5px", border: "none", outline: "none" }} size="large" />

                                    :
                                    <Button variant="contained" disabled={loading} size="large">

                                         Loading...

                                    </Button>
                                }



                                <br />
                                <br />
                            </form>
                    
                            </Box>



</Grid>




</Grid>
  
<br/>
<br/>
<br/>
<br/>
<p align="center" ><b>Download The Software</b></p>
                               <br/>

                               <Grid container>

<Grid lg={2} xs={12} md={2}></Grid>
<Grid lg={8} xs={12} md={8}>

<Container style={{}}>
     
     <Grid container spacing={2}>
 <Grid item lg={4} sm={12} md={4} >
    <p style={{fontWeight:"bolder", marginBottom:"5px", color:"#1760d3"}}>Teachers' App</p>
    <a target='blank' href='https://play.google.com/store/apps/details?id=com.sisrevolution.app'>

    <img src={Android} width="90%" />
    </a>
 </Grid>
 <Grid item lg={4} sm={12} md={4} >
 <p style={{fontWeight:"bolder", marginBottom:"5px"}}>Teachers' App</p>
 <a target='blank' href='https://apps.apple.com/app/sis-revolution-for-teachers/id6447756417' >
 <img src={Apple} width="90%" />


 </a>
 </Grid>
 <Grid item lg={4} sm={12} md={4} >
 <p style={{fontWeight:"bolder", marginBottom:"5px"}}>Administration App</p>
 
 <a href={SIS} download="SISRevolution.exe" ><img src={Windows} width="90%" /></a>
 </Grid>
 
 
 
 </Grid>
 </Container>

</Grid>
<Grid lg={2} xs={12} md={2}></Grid>

                               </Grid>
              

<Grid container></Grid>
<br/> 
<br/> 
<br/> 
   
</Container>
    </div>

      
    )
}


function Item(props)



{
    const classes=useStyles()
    return (
        
        
<div>

<div style={{position:"absolute", zIndex:1, width:"100%"}}>
<Grid container spacing={5} >
<Grid item  xs={12} sm={12} lg={6} md={6} style={{ marginTop:"0%"}}>

                       
                        <div id="App"  data-aos={"flip-right"}   style={{ color: "white", fontSize: "33px", marginTop: 10, position: "relative", zIndex: 1 }}>
                            <TiltPhaseSix
                                options={{}}

                            >
                                <TiltPhaseSix
                                    options={options}
                                >
                                    <img src={User} className="imager" alt="" />
                                </TiltPhaseSix>
                            </TiltPhaseSix>

                           

                           

                            {/* <Button style={{ backgroundImage: "linear-gradient(315deg, #ffb700 0%,  #1760d3 74%)", color: "white", zIndex: 1 }} className={classes.smaller}>
                                Download CV
                            </Button> */}
                        </div>
                        

</Grid>

<Grid item  xs={12} sm={12} lg={5} md={6}  >

<div style={{ position: "relative", zIndex: 1, marginTop: "25%",}}>
<div align="center"><hr data-aos="fade-right" style={{textAlign:"center", width:"82%", marginTop:"-80px", marginBottom:10,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>

<h1 className={classes.smaller} style={{color:"white", fontWeight:"bolder"}}>
    GET THE SOFTWARE <br/>
  <small style={{fontSize:"18px", marginTop:"-25px"}}>Manage Your School With SIS Revolution</small>

</h1>
<p className={classes.larger} style={{color:"white", fontWeight:"bolder"}}>
  <small style={{fontSize:"16px", marginTop:"-25px"}}>Manage Your School With SIS Revolution</small>

</p>
<div align="center"><hr data-aos="fade-left" style={{textAlign:"center", width:"82%", marginTop:10, marginBottom:40,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>


                     
</div>
</Grid>

</Grid>


          
</div>



<div className="particle" >


<Particles 
        id="tsparticle"
        options={{
          background: {
            color: {
              value: "",
            },
        
          },

          fpsLimit: 30,
          interactivity: {
            detectsOn: "canvas",
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: false,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 0.5,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 10,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
            particles: {
                color: {
                    value: ["#1760d3", "#1760d3", "#1760d3"]
                },
                links: {
                    color: "#1760d3",
                    distance: 150,
                    enable: true,
                    opacity: 0.2,
                    width: 0.8,
                },
                collisions: {
                    enable: false,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: true,
                    speed: 3,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        value_area: 900,
                    },
                    value: 100,
                },
                opacity: {
                    value: 0.2,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    random: true,
                    value: 5,
                },
            },
          detectRetina: true,
        }}


      />

   </div>  
   </div>
    )
}





export default Example


